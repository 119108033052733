import React, { ReactElement } from "react"
import styled from "styled-components"

import config from "src/config"
import { ItemStyled, TemplatesStyled } from "./style"
import mouseIcon from "src/componentsV2/images/icons/mouse_click.svg"
// import sterling from "./images/sterling.png"
import sterlingDesktopPNG from './images/sterling_737x621.png'
import sterlingDesktopWEBP from './images/sterling_737x621.webp'
import sterlingMobilePNG from './images/sterling_395x344.png'
import sterlingMobileWEBP from './images/sterling_395x344.webp'
// import netflix from "./images/netflix.png"
import netflixDesktopPNG from './images/netflix_737x729.png'
import netflixDesktopWEBP from './images/netflix_737x729.webp'
import netflixMobilePNG from './images/netflix_395x404.png'
import netflixMobileWEBP from './images/netflix_395x404.webp'
// import patagonia from "./images/patagonia.png"
import patagoniaDesktopPNG from './images/patagonia_737x514.png'
import patagoniaDesktopWEBP from './images/patagonia_737x514.webp'
import patagoniaMobilePNG from './images/patagonia_395x285.png'
import patagoniaMobileWEBP from './images/patagonia_395x285.webp'
// import uber from "./images/uber.png"
import uberDesktopPNG from "./images/uber_737x920.png"
import uberDesktopWEBP from "./images/uber_737x920.webp"
import uberMobilePNG from "./images/uber_395x510.png"
import uberMobileWEBP from "./images/uber_395x510.webp"
// import diaconia from "./images/diaconia.png"
import diaconiaDesktopPNG from "./images/diaconia_737x621.png"
import diaconiaDesktopWEBP from "./images/diaconia_737x621.webp"
import diaconiaMobilePNG from "./images/diaconia_395x344.png"
import diaconiaMobileWEBP from "./images/diaconia_395x344.webp"

import DigitalDescription from "./DigitalDescription"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"

const DigitalHandbookContainer = styled(ContainerPage)`
  padding-top: 0px;
  padding-bottom: 0px;
  @media screen and (min-width: 768px) {
    padding-top: 20px;
  }
`

interface TemplateProps {
  imageDOM: ReactElement
  slug: string
  title: string
}

interface DataProps {
  netflix: TemplateProps
  patagonia: TemplateProps
  uber: TemplateProps
  sterling: TemplateProps
  diaconia: TemplateProps
}

const Item = ({
  data,
  className,
}: {
  data: TemplateProps
  className?: any
}) => {
  return (
    <ItemStyled className={`${className} ${data.slug}`}>
      {data.imageDOM}
      <a
        href={`${config.HANDBOOK_HOST}/books/${data.slug}`}
        target="_blank"
        className="btn-preview"
      >
        <img src={mouseIcon} width={25} height={43} alt='pc mouse' />
        <div>Click to preview</div>
      </a>
    </ItemStyled>
  )
}

const data: DataProps = {
  netflix: {
    imageDOM: (
      <picture>
        <source srcSet={netflixDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={netflixDesktopPNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={netflixMobileWEBP} type="image/webp" />
        <source srcSet={netflixMobilePNG} type="image/png" />
        <img src={netflixDesktopPNG} alt='netflix logo' />
      </picture>
    ),
    slug: "template-32772",
    title: "Netflix Employee Handbook",
  },

  patagonia: {
    imageDOM: (
      <picture>
        <source srcSet={patagoniaDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={patagoniaDesktopPNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={patagoniaMobileWEBP} type="image/webp" />
        <source srcSet={patagoniaMobilePNG} type="image/png" />
        <img src={patagoniaDesktopPNG} alt='patagonia logo' />
      </picture>
    ),
    slug: "template-44327",
    title: "Patagonia Employee Handbook",
  },

  diaconia: {
    imageDOM: (
      <picture>
        <source srcSet={diaconiaDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={diaconiaDesktopPNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={diaconiaMobileWEBP} type="image/webp" />
        <source srcSet={diaconiaMobilePNG} type="image/png" />
        <img src={diaconiaDesktopPNG} alt='diaconia logo' />
      </picture>
    ),
    slug: "template-44999",
    title: "Diaconia Employee Handbook",
  },

  sterling: {
    imageDOM: (
      <picture>
        <source srcSet={sterlingDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={sterlingDesktopPNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={sterlingMobileWEBP} type="image/webp" />
        <source srcSet={sterlingMobilePNG} type="image/png" />
        <img src={sterlingDesktopPNG} alt='sterling logo' />
      </picture>
    ),
    slug: "template-472",
    title: "Sterling Employee Handbook",
  },

  uber: {
    imageDOM: (
      <picture>
        <source srcSet={uberDesktopWEBP} type="image/webp" media="(min-width: 426px)" />
        <source srcSet={uberDesktopPNG} type="image/png" media="(min-width: 426px)" />
        <source srcSet={uberMobileWEBP} type="image/webp" />
        <source srcSet={uberMobilePNG} type="image/png" />
        <img src={uberDesktopPNG} alt='uber logo' />
      </picture>
    ),
    slug: "template-44330",
    title: "Uber Employee Handbook",
  },
}

export const DigitalHandbook = () => {
  return (
    <DigitalHandbookContainer isBackgroundWhite data-loadable-component="digital-handbook">
      <WrapperPage>
        <TemplatesStyled id="digital">
          <DigitalDescription />

          <div className="row h-full">
            <div className="col left-col flex flex-col">
              <div className="row h-450">
                <div className="col shadow-image ">
                  <Item data={data.netflix} />
                </div>
              </div>
              <div className="row h-303 mb-10 md:mb-0">
                <div className="col shadow-image">
                  <Item data={data.sterling} />
                </div>
              </div>
            </div>
            <div className="col left-col flex flex-col">
              <div className="row h-375">
                <div className="col shadow-image">
                  <Item data={data.patagonia} />
                </div>
              </div>
              <div className="row h-310 mb-10 md:mb-0">
                <div className="col shadow-image">
                  <Item data={data.diaconia} />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="row h-550 uber shadow-image">
                <Item data={data.uber} />
              </div>
            </div>
          </div>
        </TemplatesStyled>
      </WrapperPage>
    </DigitalHandbookContainer>
  )
}
