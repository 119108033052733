import styled from "styled-components"

export const ItemStyled = styled.div`
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.5);

  .btn-preview {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    padding: 15px;
    box-shadow: 0px 2px 70px rgba(4, 2, 33, 0.44);
    transition: all 0.3s;
    opacity: 0;

    img {
      width: 25px;
      height: auto;
    }

    div {
      font-size: 16px;
      font-weight: bold;
      color: #0f1722;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.3s;
  }

  &.template-44327:after {
    transition: all 0.3s;
    background-color: rgba(15, 23, 34, 0.9);
  }

  &.template-472:after {
    background-color: rgba(16, 122, 241, 0.9);
  }

  &.template-36102:after {
    background-color: rgba(198, 198, 198, 0.9);
  }

  &.template-32472:after {
    background-color: rgba(237, 144, 82, 0.9);
  }

  &.template-44330:after {
    background-color: rgba(16, 122, 241, 0.9);
  }

  &.template-1442:after {
    background-color: rgba(235, 204, 182, 0.9);
  }

  &.template-32772:after {
    background-color: rgba(72, 136, 139, 0.9);
  }

  &.template-472:after {
    background-color: rgba(202, 219, 220, 0.9);
  }

  &.template-44330:after {
    background-color: rgba(15, 23, 34, 0.9);
  }

  &.template-44999:after {
    background-color: rgba(203, 216, 231, 0.9);
  }

  &:hover {
    .btn-preview {
      display: flex;
      opacity: 1;
    }

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    &.template-44330 {
      height: 120vw;
    }
    &.template-472 {
      height: 81vw;
    }
    &.template-44999 {
      height: 81vw;
    }
    &.template-32772 {
      height: 95vw;
    }
    &.template-44327 {
      height: 67vw;
    }
  }
`

export const TemplatesStyled = styled.div`
  .h-303 {
    height: 303px;
  }

  .h-375 {
    height: 375px;
  }

  .h-450 {
    height: 450px;
  }

  .h-310 {
    height: 310px;
  }

  .h-550 {
    height: 550px;
  }

  .row {
    display: flex;
    margin-bottom: 40px;

    &.mb-0 {
      margin-bottom: 0;
    }

    .col {
      width: 100%;
      height: 100%;

      &.h-235 {
        height: 235px;
      }
      &.left-col {
        padding-right: 35px;
      }
      &.right-col {
        padding-left: 35px;
      }
    }
  }

  .desc {
    max-width: 450px;
    margin-left: auto;
  }

  @media (max-width: 767px) {
    .row {
      flex-wrap: wrap;
      height: auto;

      .col,
      .col.left-col,
      .col.right-col {
        padding: 0;
        height: auto;
      }
      &.uber {
        margin-bottom: 0;
      }

      &.row-text {
        .col {
          margin-bottom: 40px;
          .title {
            text-align: center;
          }
        }
      }
      .big-first-col {
        margin-left: 0;
      }
      .big-second-col {
        margin-right: 0;
      }
    }
    .desc {
      margin: 0 auto;
      text-align: center;
    }
  }
`
