import styled from "styled-components"

export const DigitalDescriptionStyled = styled.div`
  .row {
    display: flex;
    margin-bottom: 40px;

    .col {
      width: 100%;
      height: 100%;
    }
  }

  .desc {
    max-width: 450px;
    margin-left: auto;
  }
  @media (max-width: 767px) {
    .row {
      flex-wrap: wrap;
      height: auto;
      margin-bottom: 0;

      .col {
        padding: 0;
        height: auto;
      }

      &.row-text {
        .col {
          margin-bottom: 40px;
          .title {
            text-align: center;
          }
        }
      }
    }
    .desc {
      margin: 0 auto;
      text-align: center;
    }
  }
`
